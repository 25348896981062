import AXIOS from "services/axios";

export const addElectronicMediaCampignAds = async (
  headline,
  state,
  tendency,
  channel,
  image_field,
  coverage_duration,
  created_date,
  log_time,
  campaign_id,
  type_id
) => {
  const response = { data: null, error: null };
  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();

  formData.append("headline", headline);
  formData.append("state", stateFinal);
  formData.append("tendancy", tendency);
  formData.append("channel", channel.id);
  formData.append("coverage_duration", coverage_duration);
  formData.append("created_date", created_date);
  formData.append("log_time", log_time);
  formData.append("type_id", type_id);
  formData.append("campaign", campaign_id);

  if (image_field) formData.append("image", image_field);
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getElectronicCampaignAds = async (
  state,
  start_date,
  end_date,
  start_time,
  end_time,
  campaign_id
) => {
  const response = { data: null, error: null, result: null };
  let stateFinal;
  let typeId;
  if (state.id === 0) {
    stateFinal = null;
    typeId = 1;
  } else {
    stateFinal = state.id;
    typeId = 2;
  }
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}&campaign=${campaign_id}`
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const updateElectronicCampaignAds = async (
  state,
  tendency,
  channel,
  image_field,
  coverage_duration,
  log_time,
  created_date,
  headline,
  campaign_id,
  userId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = null;
    type_id = 1;
  } else {
    stateFinal = state.id;
    type_id = 2;
  }
  const formData = new FormData();
  formData.append("headline", headline);
  formData.append("state", stateFinal);
  formData.append("tendancy", tendency);
  formData.append("channel", channel);
  formData.append("coverage_duration", coverage_duration);
  formData.append("created_date", created_date);
  formData.append("log_time", log_time);
  formData.append("type_id", type_id);
  formData.append("campaign", campaign_id);

  if (image_field) formData.append("image", image_field);

  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/${userId}/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const deleteElectronicCampaignAd = async (userId) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/${userId}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const updatePrintCampaignAds = async (
  state,
  newsChannel,
  tendency,
  description,
  headline,
  image,
  pageNumber,
  articlePosition,
  created_date,
  campaign_id,
  userId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = null;
    type_id = 1;
  } else {
    stateFinal = state.id;
    type_id = 2;
  }
  const formData = new FormData();
  formData.append("channel", newsChannel);
  formData.append("tendancy", tendency);
  formData.append("page", pageNumber);
  formData.append("article_position", articlePosition);
  formData.append("state", stateFinal);
  formData.append("description", description);
  formData.append("headline", headline);
  formData.append("created_date", created_date);
  formData.append("type_id", type_id);
  formData.append("campaign", campaign_id);

  if (image) formData.append("image", image);
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/${userId}/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const addPrintMediaCampaignAds = async (
  state,
  tendency,
  newsChannel,
  image,
  pageNumber,
  articlePosition,
  created_date,
  description,
  headline,
  campaign_id,
  typeId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  formData.append("channel", newsChannel);
  formData.append("tendancy", tendency);
  formData.append("page", pageNumber);
  formData.append("article_position", articlePosition);
  formData.append("state", stateFinal);
  formData.append("description", description);
  formData.append("headline", headline);
  formData.append("created_date", created_date);
  formData.append("type_id", typeId);
  formData.append("campaign", campaign_id);

  if (image) formData.append("image", image);
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const deletePrintCampaignAd = async (userId) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/${userId}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getPrintCampaignAd = async (
  state,
  start_date,
  end_date,
  start_time,
  end_time,
  campaign_id
) => {
  const response = { data: null, error: null, result: null };

  let stateFinal;
  let typeId;
  if (state.id === 0) {
    stateFinal = null;
    typeId = 1;
  } else {
    stateFinal = state.id;
    typeId = 2;
  }
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}&campaign=${campaign_id}`
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const addDigitalMediaCampaignAds = async (
  state,
  tendency,
  newsChannel,
  created_date,
  typeId,
  description,
  headline,
  media_url,
  campaign_id
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  formData.append("channel", newsChannel);
  formData.append("headline", headline);
  formData.append("tendancy", tendency);
  formData.append("media_url", media_url);
  formData.append("description", description);
  formData.append("state", stateFinal);
  formData.append("created_date", created_date);
  formData.append("type_id", typeId);
  formData.append("campaign", campaign_id);

  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/mmt/addigital/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const updateDigitalCampaignAds = async (
  state,
  tendency,
  newsChannel,
  media_url,
  description,
  headline,
  created_date,
  userId,
  campaign_id
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = null;
    type_id = 1;
  } else {
    stateFinal = state.id;
    type_id = 2;
  }
  const formData = new FormData();

  formData.append("channel", newsChannel);
  formData.append("headline", headline);
  formData.append("tendancy", tendency);
  formData.append("media_url", media_url);
  formData.append("description", description);
  formData.append("state", stateFinal);
  formData.append("created_date", created_date);
  formData.append("type_id", type_id);
  formData.append("campaign", campaign_id);
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/addigital/${userId}/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const deleteDigitalCampaignAd = async (userId) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/addigital/${userId}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getDigitalCampaignAd = async (
  state,
  start_date,
  end_date,
  start_time,
  end_time,
  campaign_id
) => {
  const response = { data: null, error: null, result: null };

  let stateFinal;
  let typeId;
  if (state.id === 0) {
    stateFinal = null;
    typeId = 1;
  } else {
    stateFinal = state.id;
    typeId = 2;
  }
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/addigital/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}&campaign=${campaign_id}`
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getOverallCampaignAd = async (
  state,
  start_date,
  end_date,
  start_time,
  end_time,
  campaign_id
) => {
  const response = { data: null, error: null, result: null };

  let stateFinal;
  let typeId;
  if (state.id === 0) {
    stateFinal = null;
    typeId = 1;
  } else {
    stateFinal = state.id;
    typeId = 2;
  }
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/totalcompaign/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}&campaign=${campaign_id}`
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getCampaignList = async()=>{
  const response = { data: null, error: null, result: null };

  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/campaign/`
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response; 
}